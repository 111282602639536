import React from "react"

import PageContent from "../components/shared/PageContent"
import TransError from "../components/shared/TransError"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <>
    <TransError />
    <SEO title="404: Not found" />

    <PageContent>
      <h1>NOT FOUND</h1>
      <iframe
        src="https://giphy.com/embed/c20UV66B7zCWA"
        width="480"
        height="310"
        title="Not found GIF"
        frameBorder="0"
        class="giphy-embed"
        allowFullScreen
      />
    </PageContent>
  </>
)

export default NotFoundPage
